<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <content-not-view v-else-if="!subPermission.store || !subPermission.update" />

  <div v-else>
    <validation-observer ref="ACARForm">
      <b-form>
        <b-card :title="$t('g.addSample')">
          <b-row>
            <b-col md="4">
              <b-form-group :label="$t('g.sample_code')">
                <validation-provider
                  #default="{ errors }"
                  name="sample code"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.sample_code"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.EnterTheSampleCodeHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Request Type -->
            <b-col md="4">
              <b-form-group :label="$t('g.lab_name')">
                <validation-provider
                  #default="{ errors }"
                  name="lab"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.lab_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="labs"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Request Type -->

            <!-- Assigned to -->
            <b-col md="4">
              <b-form-group :label="$t('g.number_of_test_request')">
                <validation-provider
                  #default="{ errors }"
                  name="number of test request"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.number_of_test_request"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.EnterTheNumberOfTestRequestHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('g.requestDate')">
                <validation-provider
                  #default="{ errors }"
                  name="request date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.request_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('g.received_date')">
                <validation-provider
                  #default="{ errors }"
                  name="received date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.received_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4"></b-col>

            <b-col md="4" class="mb-1">
              <b-form-group
                :label="$t('g.disposalReport')"
                label-for="disposal report"
              >
                <b-form-file
                  :placeholder="$t('g.uploadYourFileHere')"
                  drop-placeholder="Drop file here..."
                  multiple
                  v-model="form_data.disposal_report"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" class="mb-1">
              <b-form-group
                :label="$t('g.procedureSamplingReport')"
                label-for="procedure sampling report"
              >
                <b-form-file
                  :placeholder="$t('g.uploadYourFileHere')"
                  drop-placeholder="Drop file here..."
                  multiple
                  v-model="form_data.procedure_sampling_report"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group :label="$t('g.description')">
                <b-form-textarea
                  id="textarea-default"
                  :placeholder="$t('g.placeholder.enterTheDescriptionHere')"
                  v-model="form_data.description"
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group :label="$t('g.notes')">
                <b-form-textarea
                  id="textarea-default"
                  :placeholder="$t('g.placeholder.enterTheNotesHere')"
                  v-model="form_data.notes"
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <!-- Submit Button -->
            <b-col md="12">
              <b-button variant="primary" @click.prevent="validationForm">
                {{ $t("g.submit") }}
              </b-button>
            </b-col>

            <!-- ** Submit Button -->
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import { required } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import store from "@/store/index";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BButton,
    BFormFile,
    Loading,
    Error,
    ContentNotView,
  },
  data() {
    return {
      serviceRequestId: null,
      techEvaluationId: null,
      form_data: {
        sample_code: null,
        lab_id: null,
        request_date: null,
        received_date: null,
        notes: null,
        description: null,
        number_of_test_request: null,
        disposal_report: null,
        procedure_sampling_report: null,
      },
      labs: [],
      subPermission: {},
      isLoading: true,
      isError: null,
    };
  },

  created() {
    this.serviceRequestId = this.$route.params.id;
    this.techEvaluationId = this.$route.params.tech_id;
  },

  beforeMount() {
    this.getLabs();
  },

  methods: {
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
      }
    },

    getLabs() {
      this.$http
        .get(`admin/labs`)
        .then((res) => {
          this.labs = res.data.data;
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "samples");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          this.isError = true;
          console.log(err);
        });
    },

    submitForm() {
      console.log(this.form_data);
      let formData = new FormData();
      for (let item in this.form_data) {
        if (
          item === "disposal_report" ||
          item === "procedure_sampling_report"
        ) {
          if (this.form_data[item] !== null) {
            formData.append(item, this.form_data[item][0]);
          } 
        } else {
          formData.append(item, this.form_data[item]);
        }
      }

      this.$http
        .post(
          `admin/serviceRequests/${this.serviceRequestId}/techEvaluations/${this.techEvaluationId}/samples`,
          formData
        )
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );

          setTimeout(() => {
            this.$router.push({ name: "ViewTechEvaluation" });
          }, 500);
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },

    validationForm() {
      this.$refs.ACARForm.validate().then((success) => {
        if (success) {
          this.submitForm();
          // this.clearForm();
          // this.$refs.ACARForm.reset();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>

<style></style>
